import React from "react";
import Footer from "src/components/Footer_it";

class Page extends React.Component {
  render() {
    return (
      <div>
       
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/it_IT/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/it/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/it/prodotti/" target="_self">
                      Prodotti
                    </a>
                    <ul>
                      <li>
                        <a href="/it/prodotti/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/active-protect/">
                          Active &amp; Protect
                        </a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/moisturising/">Moisturising</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      {/* <li>
                        <a href="/it/prodotti/instantglow/">Instant Glow</a>
                      </li> */}
                      <li>
                        <a href="/it/prodotti/allergy/">Allergy</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/it/our-heritage/" target="_self">
                      Storia
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/it/know_the_sun/" target="_self">
                      Conosci il sole
                    </a>
                  </li>
                  {/*<li>
                    <a className="es_header" href="/it/Ma-Dove-Sei-Stata/" target="_self">
                      Ma Dove Sei Stata 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      Paesi{" "}
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/tan_protect/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/tan-protect/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/tan_protect/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/tan_protect/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2020-tan-protect.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/2020-tan-protect-mobile.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN TAN &amp; PROTECT<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Aumenta la naturale abbronzatura</h3>
                      <p>
                        Per un week end di sole o una lunga rilassante vacanza
                        in spiaggia, l’olio, lo spray e il latte solare PIZ BUIN
                        TAN &amp; PROTECT<sup>®</sup> ti consentiranno di
                        ottenere il massimo dalla tua abbronzatura. Tutti i
                        prodotti PIZ BUIN TAN &amp; PROTECT<sup>®</sup>{" "}
                        associano l’efficace protezione UVA/UVB al Illumitone
                        <sup>™</sup>, ingrediente innovativo che accelera il
                        naturale processo di abbronzatura della pelle* .Nutre la
                        pelle, lasciandola liscia, con un meraviglioso tocco di
                        luce. Incrementando la produzione dei pigmenti
                        abbronzanti della pelle fino al 70%*, è provato che il
                        Illumitone<sup>™</sup> accelera il processo di
                        abbronzatura naturale** per un’abbronzatura naturalmente
                        più rapida*** e più bella.
                      </p>
                      <p className="footNote">
                        *Spray e Latte solare, test in vitro **Test in vitro ***
                        Contiene una tecnologia che aumenta la naturale
                        abbronzatura
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="olio-spray-solare-acceleratore-dell-abbronzatura" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-oil-spray-30spf-150ml.png" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>OLIO SPRAY SOLARE ACCELERATORE DELL’ABBRONZATURA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Il nostro Olio Spray Acceleratore dell'abbronzatura ti
                          consentirà di ottenere rapidamente una bellissima
                          abbronzatura in maniera sicura e di goderti ogni
                          attimo che passerai al sole la prossima estate. Gli
                          olii abbronzanti tradizionali possono donare un
                          aspetto fantastico alla tua abbronzatura ma offrono
                          una protezione minima o nulla, laddove applicare le
                          normali creme solari con SPF elevato può significare
                          attendere troppo a lungo per ottenere l'abbronzatura
                          estiva che desideri. Come puoi ottenere una bellissima
                          abbronzatura naturale senza compromettere la
                          protezione della pelle?
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Olio Spray
                            Solare Acceleratore dell’Abbronzatura è proprio
                            quello che fa per te. Il nostro olio a rapido
                            assorbimento, non-grasso, facile da applicare
                            combina un’efficace protezione UVA e UVB con
                            Melitane, un ingrediente innovativo che accelera il
                            naturale processo di abbronzatura della pelle*.
                            Nutre la pelle, lasciandola liscia, con un
                            meraviglioso tocco di luce. PIZ BUIN TAN &amp;
                            PROTECT<sup>®</sup> Olio Spray Solare Acceleratore
                            dell’Abbronzatura. Per una abbronzatura più rapida,
                            più bella ... in tutta sicurezza!
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Olio Spray
                            Solare Acceleratore dell’Abbronzatura è resistente
                            al sudore e all’acqua.
                          </p>
                          <p>
                            PIZ BUIN TAN &amp; PROTECT<sup>®</sup> Olio Spray
                            Solare Acceleratore dell'Abbronzatura incontra il
                            favore dei consumatori. Abbiamo chiesto a 102
                            consumatori che cosa ne pensano**. E dopo quattro
                            settimane di test…
                          </p>
                          <p>
                            <strong>
                              <span className="orange">L'80%</span>
                            </strong>{" "}
                            ha constatato:
                          </p>
                          <ul>
                            <li>Un'abbronzatura naturale più intensa</li>
                          </ul>
                          <p>
                            <strong className="orange">L'84%</strong> ha
                            constatato:
                          </p>
                          <ul>
                            <li>Una tonalità lucida e splendente</li>
                          </ul>
                          <p>
                            <strong className="orange">L'97%</strong> ha
                            constatato:
                          </p>
                          <ul>
                            <li>Un’efficace protezione solare</li>
                          </ul>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Leggi di piu´
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Diminuendo la quantità il livello di
                          protezione si riduce sensibilmente. Riapplicare
                          soprattutto dopo aver sudato, nuotato o essersi
                          asciugati. Evitare l'esposizione al sole nelle ore
                          centrali del giorno. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole. Non spruzzare
                          direttamente sul viso. Per l'uso sul viso, spruzzare
                          sulle mani e applicare evitando il contatto con gli
                          occhi.
                        </p>
                        <p className="footNote">*Test in vitro</p><br />
                        <p className="footNote">
                          **Risultati di autovalutazione ottenuti utilizzando lo
                          Spray SPF 30, su 102 soggetti, applicato almeno 1
                          volta al giorno per 4 settimane.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Vitamina E
                                <div className="textBlock bottom">
                                  <p>
                                    La vitamina E è un potente antiossidante
                                    naturale. Aiuta la protezione dai radicali
                                    liberi che danneggiano le cellule cutanee e
                                    causano l'invecchiamento precoce della
                                    pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    È provato che il Illumitone™ accelera il
                                    naturale processo di abbronzatura.
                                    Incrementa la produzione dei pigmenti
                                    abbronzanti naturali del corpo fino al 70%*.
                                    Al contrario di altri ingredienti
                                    abbronzanti, il Illumitone™ non macchia
                                    vestiti, costumi e asciugamani.
                                  </p>
                                  <p className="footNote">
                                    *Spray e Latte Solare, test in vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="lozione-solare-intensificatore-dell-abbronzatura" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/eff51ba86541ed918048fedf6e5e85ee_f28.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>LOZIONE SOLARE INTENSIFICATORE DELL’ABBRONZATURA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          La formula non grassa né appiccicosa di PIZ BUIN TAN
                          &amp; PROTECT® Lozione Solare Intensificatrice
                          dell’Abbronzatura idrata intensamente la pelle per
                          prevenire la desquamazione e contribuisce a prolungare
                          l’abbronzatura. Leggi di più
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e uniformemente prima di
                          esporsi al sole. Usare una quantità insufficiente
                          riduce sensibilmente il livello di protezione.
                          Riapplicare frequentemente soprattutto dopo aver
                          sudato, nuotato o essersi asciugati con un
                          asciugamano. Evitare l'esposizione al sole nelle ore
                          centrali del giorno e per lungo tempo, anche se si
                          usano filtri solari. Tenere neonati e bambini al
                          riparo dalla luce diretta del sole.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Vitamina E
                                <div className="textBlock bottom">
                                  <p>
                                    La vitamina E è un potente antiossidante
                                    naturale. Aiuta la protezione dai radicali
                                    liberi che danneggiano le cellule cutanee e
                                    causano l'invecchiamento precoce della
                                    pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    È provato che il Illumitone™ accelera il
                                    naturale processo di abbronzatura.
                                    Incrementa la produzione dei pigmenti
                                    abbronzanti naturali del corpo fino al 70%*.
                                    Al contrario di altri ingredienti
                                    abbronzanti, il Illumitone™ non macchia
                                    vestiti, costumi e asciugamani.
                                  </p>
                                  <p className="footNote">
                                    *Spray e Latte Solare, test in vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="products-page__product clearfix">
                    <a name="spray-solare-intensificatore-dell-abbronzatura" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/2019-tan-protect-tan-intensifying-sun-spray-30spf-150ml.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>SPRAY SOLARE INTENSIFICATORE DELL’ABBRONZATURA</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>Benefici</h4>
                        <p>
                          Il nostro Spray Solare Intensificatore
                          dell’Abbronzatura abbina un’efficace protezione
                          UVA/UVB all’IllumitoneTM.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>Leggi di più</p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Leggi di piu´
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>SPF disponibili</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>Modo d’uso</h4>
                        <p>
                          Applicare abbondantemente e riapplicare
                          frequentemente. Una quantità insufficiente riduce il
                          livello di protezione. Evitare l'esposizione al sole
                          nelle ore centrali del giorno e per lungo tempo.
                          Tenere neonati e bambini al riparo dalla luce diretta
                          del sole. Per l’uso sul viso, spruzzare sulle mani e
                          applicare. Usare con precauzione, in quanto può
                          rendere scivoloso il pavimento.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTI E TECNOLOGIE{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Sistema di filtri solari avanzati UVA/UVB di PIZ
                                BUIN<sup>®</sup>
                                <div className="textBlock bottom">
                                  <p>
                                    Come specialisti di prodotti solari, usiamo
                                    tecnologie innovative per garantire il
                                    giusto equilibrio tra abbronzatura e
                                    protezione tutte le volte che ti esponi al
                                    sole.
                                  </p>
                                  <p>
                                    Ogni sistema di filtri solari avanzati
                                    UVA/UVB di PIZ BUIN<sup>®</sup> è una
                                    tecnologia all’avanguardia sviluppata da
                                    Kenvue per la protezione dal
                                    sole. Questo sistema offre una protezione
                                    fotostabile ad ampio spettro dai raggi UVA e
                                    UVB, pertanto le protezioni solari Piz Buin
                                    osservano le ultime direttive Europee.
                                  </p>
                                  <p>
                                    Raggi UVB - "i raggi che scottano" -
                                    danneggiano la superficie cutanea e sono la
                                    principale causa di scottature.
                                  </p>
                                  <p>
                                    Raggi UVA - "i raggi che invecchiano" -
                                    penetrano più a fondo e danneggiano
                                    collagene ed elastina, il che può provocare
                                    l'invecchiamento precoce della pelle e il
                                    cancro.
                                  </p>
                                  <p>
                                    I sistemi avanzati di filtri solari UVA/UVB
                                    di PIZ BUIN<sup>®</sup> contengono sia
                                    filtri UVA che UVB per proteggerti dal sole
                                    e dai danni a lungo termine, ma anche dagli
                                    effetti più gravi provocati dai raggi UVA.
                                    Coprono gran parte dello spettro UVA/UVB,
                                    offrendo un'ampia gamma di protezione per la
                                    tua pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Vitamina E
                                <div className="textBlock bottom">
                                  <p>
                                    La vitamina E è un potente antiossidante
                                    naturale. Aiuta la protezione dai radicali
                                    liberi che danneggiano le cellule cutanee e
                                    causano l'invecchiamento precoce della
                                    pelle.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                Illumitone™
                                <div className="textBlock bottom">
                                  <p>
                                    È provato che il Illumitone™ accelera il
                                    naturale processo di abbronzatura.
                                    Incrementa la produzione dei pigmenti
                                    abbronzanti naturali del corpo fino al 70%*.
                                    Al contrario di altri ingredienti
                                    abbronzanti, il Illumitone™ non macchia
                                    vestiti, costumi e asciugamani.
                                  </p>
                                  <p className="footNote">
                                    *Spray e Latte Solare, test in vitro
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/226770468d322e412d20107058a97d32_f541.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/it/prodotti/hydro-infusion/"
                      target="_self"
                    >
                      Hydro Infusion
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/active-protect/" target="_self">
                      Active &amp; Protect
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/moisturising/" target="_self">
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/it/prodotti/tan_protect/"
                      target="_self"
                    >
                      Tan &amp; Protect
                    </a>
                  </li>
                  {/* <li>
                    <a href="/it/prodotti/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li> */}
                  <li>
                    <a href="/it/prodotti/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/it/prodotti/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.facebook.com/PizBuinItalia?fref=ts"
                target="_blank"
                className="fb"
              >
                &nbsp;
              </a>
              <a
                href="https://instagram.com/pizbuin_italia/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// Conversion Name: LP/HP Piz buin\n// INSTRUCTIONS \n// The Conversion Tags should be placed at the top of the <BODY> section of the HTML page. \n// In case you want to ensure that the full page loads as a prerequisite for a conversion \n// being recorded, place the tag at the bottom of the page. Note, however, that this may \n// skew the data in the case of slow-loading pages and in general not recommended. \n//\n// NOTE: It is possible to test if the tags are working correctly before campaign launch \n// as follows:  Browse to http://bs.serving-sys.com/Serving/adServer.bs?cn=at, which is \n// a page that lets you set your local machine to 'testing' mode.  In this mode, when \n// visiting a page that includes an conversion tag, a new window will open, showing you \n// the data sent by the conversion tag to the MediaMind servers. \n// \n// END of instructions (These instruction lines can be deleted from the actual HTML)\nvar ebRand = Math.random()+'';\nebRand = ebRand * 1000000;\ndocument.write('<scr'+'ipt src=\"HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;rnd=' + ebRand + '\"></scr' + 'ipt>');\n"
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html:
              '\n         <img width="1" height="1" style="border:0" src="HTTP://bs.serving-sys.com/Serving/ActivityServer.bs?cn=as&amp;ActivityID=628188&amp;ns=1">\n      '
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Leggi di piu´');\n} else {\nbox.slideDown();\n$(this).text('Leggi meno');\n}\n});\n});\n"
          }}
        />
        
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
